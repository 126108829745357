import React from "react";
import classes from "./Page2.module.css";

const Page2 = () => {
  return (
    <div className={classes.Page2Main}>
      <div className={classes.Page2Parent}>
        <h6>&ldquo;Upholding Honesty in Every Action&rdquo;</h6>
        <h3>INTEGRITY</h3>
      </div>
      <div className={classes.Page2Parent}>
        <h6>&ldquo;Striving for the Highest Standards&rdquo;</h6>
        <h3>EXCELLENCE</h3>
      </div>
      <div className={classes.Page2Parent}>
        <h6>&ldquo;Pioneering Progressive Solutions&rdquo;</h6>
        <h3>INNOVATION</h3>
      </div>
    </div>
  );
};

export default Page2;
