import React from "react";
import Blog1 from "./Blog1";

const BlogIndex = () => {
  return (
    <div>
      <Blog1 />
    </div>
  );
};

export default BlogIndex;
