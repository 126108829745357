import React from "react";
import classes from "./footer.module.css";

// import aacLogo from "../assets/images/aacLogo.svg";
// import Linkedin from "../assets/images/Linkedin.svg";
// import Facebook from "../assets/images/Facebook.svg";
// import Instagram from "../assets/images/Instagram.svg";
// import Twitter from "../assets/images/Twitter.svg";
// import Youtube from "../assets/images/Youtube.svg";

const FooterScreen = () => {
  return (
    <div className={`container-fluid ${classes.footerMainParent}`}>
      <div className={`container`}>
        <div className={classes.footerMain}>
          <div className={classes.footerParent}>
            <a href="/">
              <img src="/aacLogo.svg" alt="AAC " width={150} height={78} />
            </a>
            <h6>Address:</h6>
            <p>Block 6 Flat 2 Oshodi drive, Dolphin Estate </p>

            <h6>Contact:</h6>
            <p>
              08137671921 <br />
              +2347067054810{" "}
            </p>
            <h6>Email</h6>
            <p>
              Info@abegbeagboolachambers.com <br />
              Abegbeagboolachambers@yahoo
            </p>

            <div className={classes.footerIcons}>
              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className={classes.footericons2}>
                  <img src="/Linkedin.svg" width={40} height={78} alt="" />

                  {/* <h6>LinkedIn</h6> */}
                </div>
              </a>

              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className={classes.footericons2}>
                  <img src="/Instagram.svg" alt="" width={40} height={78} />

                  {/* <h6>Instagram</h6> */}
                </div>
              </a>

              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className={classes.footericons2}>
                  {" "}
                  <img src="/Facebook.svg" alt="" width={40} height={78} />
                  {/* <h6>Facebook</h6> */}
                </div>
              </a>
              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className={classes.footericons2}>
                  {" "}
                  <img src="/Youtube.svg" alt="" width={40} height={78} />
                  {/* <h6>Youtube</h6> */}
                </div>
              </a>
              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className={classes.footericons2}>
                  {" "}
                  <img src="/Twitter.svg" alt="" width={40} height={78} />
                  {/* <h6>Twitter</h6> */}
                </div>
              </a>
            </div>
          </div>

          <div className={classes.footerChildren}>
            <div className={classes.footerChild}>
              <h3>Practice Areas</h3>
              <a href="/practice-areas">
                <p>Chieftaincy matters</p>
                <p>Corporate & Commercial Practice</p>
                <p>Dispute Resolution</p>
                <p>Banking & Finance</p>
                <p>Medical & Health law</p>
                <p>Labour law</p>
                <p>Corporate law</p>
              </a>
            </div>
            <div className={classes.footerChild}>
              <h3>About us</h3>
              <a href="/aboutus">
                <p>About AAC</p>
              </a>
              <p>Our Vision</p>
              <p>Our Mission</p>
              <p>Our Team</p>
            </div>
            <div className={classes.footerChild}>
              <a href="/post">
                <h3>News & Insights</h3>
                <p>Our blog</p>
              </a>
            </div>
            {/* <div className={classes.footerChild}>
              <Link href="/contact-us">
                <h3>Get in touch with us</h3>
            
              </Link>
            </div> */}
          </div>

          <div className={classes.footerIconsMobile}>
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className={classes.footericons2}>
                <img src="/Linkedin.svg" width={40} height={78} alt="" />
              </div>
            </a>

            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className={classes.footericons2}>
                <img src="/Instagram.svg" width={40} height={78} alt="" />
              </div>
            </a>

            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div className={classes.footericons2}>
                <img src="/Facebook.svg" width={40} height={78} alt="" />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className={classes.footerCopyright}>
        <p>© 2023 Abegbe Abgoola Chambers. All rights reserved.</p>
      </div>
    </div>
  );
};

export default FooterScreen;
