import React from "react";
import classes from "./About2.module.css";

const About2 = () => {
  return (
    <div className= {`container ${classes.About2Main}`}>


      <div className= {classes.About2Parent} >
        <h6>Our Journey</h6>
      </div>

      <div className= {classes.About2Child}>
        <h2>Our Journey Through Legal Excellence</h2>
        <p>
          Founded in [Year], Abegbe Agboola Chambers has been a beacon of legal
          expertise for over two and a half decades. From humble beginnings to
          becoming a trusted name in the legal industry, our journey is marked
          by unwavering commitment to our clients and a passion for excellence.
        </p>
      </div>  
  
      <div className= {classes.AboutBro}>
        <p>We are a firm that has long service delivery to our clients . We push boundaries through innovation within the legal framework. The firm has strong presence in general litigation, Construction law , chieftancy matters , Real estate and general commercial practice. </p>
      </div>


    </div>
  );
};

export default About2;
