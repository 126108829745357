import React from "react";
import classes from "./Page1.module.css";
// import HomePage1 from "../../assets/images/HomePage1.svg";

const Page1 = () => {
  const borderRadiusStyle = {
    borderRadius: "10px",
  };

  return (
    <div className={classes.page1Primary}>
      <div className="container">
        <div className={classes.Page1Parent}>
          <div className={classes.Page1Child}>
            <h2 className={classes.page1Main}>
              {" "}
              Pushing Legal Boundaries, Delivering Excellence{" "}
            </h2>
            <p>Welcome to Abegbe Agboola Chambers</p>
          </div>
          <div className={classes.page1Sister}>
            <img
              src="/HomePage1.svg"
              alt="Abegbe Agboola Chambers"
              height={800}
              layout="responsive"
              style={borderRadiusStyle}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page1;
