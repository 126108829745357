import React from "react";
import styled from "styled-components";
import { wordLimit } from "../Util/WordCount";
import { format } from "date-fns";

const NewPostCard = ({ item, onItemClick }) => {
  const handleReadingCount = (article = "") => {
    const wordsPerMinute = 200;
    const wordsArr = article.split(" ");
    const wordsLength = wordsArr.length;
    const readingTime = Math.ceil(wordsLength / wordsPerMinute);
    return readingTime;
  };
  console.log(item, "item");

  // const thumbnail = item?.thumbnail;
  const description = item?.description;
  const firstP = description.match(/<p>.*?<\/p>/)[0];
  const div = document.createElement("div");
  div.innerHTML = firstP;
  const content = div.textContent;
  const date = item?.pubDate;

  return (
    <NewPostParent onClick={onItemClick}>
      <NewPostTitle>{item?.title}</NewPostTitle>
      <NewPostparagraph> {wordLimit(290, content ?? "")}</NewPostparagraph>

      <div>
        <StyleHeadingTwo>{item?.author}</StyleHeadingTwo>
        <StyleParagraph>
          {date && format(new Date(date), "MMM dd, yyyy")} .{" "}
          {handleReadingCount(content)}min read
        </StyleParagraph>
      </div>
    </NewPostParent>
  );
};

export default NewPostCard;

const NewPostParent = styled.div`
  width: 70% !important;
`;

const StyleHeadingTwo = styled.h6`
  text-align: left !important;
  text-transform: capitalize !important;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.84px;
  color: var(--blue-blue-500, #1c233d);
`;

const StyleParagraph = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #0c0c0c;
  text-align: left;
`;

const NewPostTitle = styled.h6`
  text-align: left !important;
  text-transform: capitalize !important;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 128.571% */
  letter-spacing: -0.84px;
  padding-top: 0.5rem;
  color: var(--blue-blue-500, #1c233d);

  @media screen and (max-width: 576px) {
    .main h1 {
      font-weight: 400;
      font-size: 18px;
    }
  }

  @media screen and (min-width: 577px) and (max-width: 768px) {
    .main h1 {
      text-align: left !important;
      font-family: "Clash Grotesk";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 120%;
      margin-top: 1rem;
      width: 100% !important;
      margin-left: 0% !important;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 922px) {
    .main h1 {
      font-size: 24px;
      line-height: 120%;
      margin-top: 1rem;
      font-weight: 400;
    }
  }
  @media screen and (min-width: 923px) and (max-width: 1200px) {
    .main h1 {
      font-size: 24px;
      line-height: 120%;
      margin-top: 1rem;
      font-weight: 400;
    }
  }
`;

const NewPostparagraph = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  color: #0c0c0c;
  text-align: left;
  margin-top: 0.5%;
`;
