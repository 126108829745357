import React from "react";
import classes from "./Practice4.module.css";
import { useNavigate } from "react-router-dom";

const Practice4 = () => {
  const navigate = useNavigate();

  const goToContact = () => {
    navigate("/contact-us");
  };
  const goToNews = () => {
    navigate("/post");
  };
  return (
    <div className={classes.Practice4GrandMain}>
      <div className={`container ${classes.Practice4Main} `}>
        <div className={classes.Practice4Parent}>
          {" "}
          <h6>Discover More</h6>{" "}
        </div>

        <div className={classes.Practice4Child}>
          <div className={classes.Practice4SubChild}>
            <h4>Our Expertise</h4>
            <div className={classes.Practice4Div}></div>
            <h5>Stories of Impact: Realizing Justice for Our Clients</h5>
            <button onClick={goToContact}> Contact us</button>
          </div>

          <div className={classes.Practice4SubChild}>
            <h4>News & Insights</h4>
            <div className={classes.Practice4Div}></div>
            <h5>Our team is ready to assist you on your inquiries</h5>
            <button onClick={goToNews}> Read more</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Practice4;
