import React from "react";
import classes from "./Page3.module.css";

const Page3 = () => {
  return (
    <div className={classes.page3Container}>
      <div className="container">
        <div className={classes.page3Parent}>
          <h6>About Us</h6>
        </div>   
        <div className={classes.page3Child}>
          <div className= {classes.page3Child1}>
            <h3>ABEGBE AGBOOLA CHAMBERS</h3>
          </div>
          <div className= {classes.page3Child2}>
            <h5>
              With over two and a half decades of experience, we pride ourselves
              on providing excellent service and innovative solutions to our
              diverse clientele. Our team of dedicated legal experts is
              committed to pushing the boundaries of traditional legal practice,
              ensuring we deliver exceptional results for our clients.
            </h5>
          </div>
        </div>
      </div>  
    </div>
  );
}; 

export default Page3;
