import React from "react";
import classes from "./About4.module.css";

const About4 = () => {
  return (
    <div className= {`container ${classes.About4Main}`}>

      <div className= {classes.About4Parent}>
        <div className= {classes.About4Child}>
          <h2>Our Mission</h2>
          <div className= {classes.About4Child1}></div>
          <p>
            To deliver prompt, excellent, innovative and effective solutions to
            our clients.
          </p>  
        </div>

        <div className= {classes.About4Child}>  
          <h2>Our Vision</h2>
          <div className= {classes.About4Child1}></div>
          <p>
            Topnotch legal service and business solutions provider , we deliver
            high quality services to our clients, with global outlook.
          </p>
        </div>
      </div>

    </div>
  );
};

export default About4;
