import React from "react";
// import Page5Img from "../../assets/images/Page5Img.svg";
import classes from "./Page5.module.css";
import { useNavigate } from "react-router-dom";

const Page5 = () => {
  const navigate = useNavigate();

  const navigateNext = () => {
    navigate("/about-us");
  };

  return (
    <div className={classes.Page5Main}>
      <div className={`container ${classes.Page5Parent}`}>
        <div className={classes.Page5People}>
          <h6>Our People</h6>
        </div>

        <div className={classes.Page5Child}>
          <div className={classes.Page5Child1}>
            <h2>Global expertise in diverse areas of law</h2>
            <p>
              Abegbe Agboola Chambers is seeking a website redesign to enhance
              their online presence and provide a user-friendly experience
            </p>
            <button onClick={navigateNext}>Meet the Team</button>
          </div>

          <div className={classes.Page5Child2}>
            <img
              src="/Page5Img.svg"
              layout="responsive"
              alt="Abegbe Agboola Chambers"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page5;
