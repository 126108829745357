import React from "react";
import classes from "./About6.module.css";
import Team from "./Team";

const About6 = () => {
  return (
    <div className= {classes.About6Primary}>
      <div className={`container ${classes.About6Main}`}>
        <div className={classes.About6Parent}>
          <h6>Faces of Expertise</h6>
        </div>

        <div className={classes.About6Child}>
          <h2>Meet the Minds Behind Our Success</h2>
          <div className={classes.About6Child1}></div>
          <p>
            We believe in going beyond traditional norms, employing innovative
            methodologies and leveraging technology to provide efficient and
            effective legal solutions. Our clients benefit from a
            forward-thinking approach that maximizes results.
          </p>
        </div>
        <div>
          <Team />
        </div>
      </div>
    </div>
  );
};

export default About6;
