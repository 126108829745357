import React from "react";
import classes from "./About1.module.css";
// import AboutUs from "../../assets/images/AboutUs.svg";

const About1 = () => {
  return (
    <div className={`container ${classes.About1Main}`}>
      <div className={classes.AboutParent}>
        <h6>About Us</h6>
        <p>Where Tradition Meets Innovation in Legal Practice</p>
      </div>
      <div className={classes.AboutChild}>
        <img src="/AboutUs.svg" alt="" height={500} layout="responsive" />
  
      </div>
    </div>
  );
};

export default About1;
