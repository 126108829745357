import React from "react";
import Practice1 from "./Practice1";
import Practice2 from "./Practice2";
import Practice3 from "./Practice3";
import Practice4 from "./Practice4";

const PracticeIndex = () => {
  return (
    <div>
      <Practice1 />
      <Practice2 />
      <Practice3 />
      <Practice4 />
    </div>
  );
};

export default PracticeIndex;
