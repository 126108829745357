import React from "react";
import classes from "./About5.module.css";
// import UniqueApproach from "../../assets/images/UniqueApproach.svg";

const About5 = () => {
  return (
    <div className={`container ${classes.About5Main}`}>
      <div className={classes.About5Parent}>
        <h6>A Unique Approach</h6>
      </div>

      <div className={classes.About5Child}>
        <h2>
          Beyond <span>Conventional Legal Practice</span>
        </h2>
        <div className={classes.About5Child1}></div>
        <p>
          We believe in going beyond traditional norms, employing innovative
          methodologies and leveraging technology to provide efficient and
          effective legal solutions. Our clients benefit from a forward-thinking
          approach that maximizes results.
        </p>
      </div>
      <div className={classes.About5Img}>
        <img src="/UniqueApproach.svg" alt="" />
      </div>
    </div>
  );
};

export default About5;
