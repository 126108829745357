import React, { useState, useEffect } from "react";
import styled from "styled-components";
import NewPostCard from "./NewPostCard";
import SinglePostModal from "./Modal/SinglePostModal";
import styles from "./Blog1.module.css";
// import BlindJustice from "../../assets/images/BlindJustice.svg";

const Blog1 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [mediumPage, setMediumPage] = useState();

  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchBlog();
  }, []);

  const fetchBlog = async () => {
    setIsLoading(true);
    fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@IsholaAgboola"
    )
      .then((res) => res.json())
      .then((data) => {
        setMediumPage(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true); // Open the modal
  };

  return (
    <div className="container">
      <div className={styles.BlogGrandParent}>
        <div className={`container ${styles.blogMainParent}`}>
          <div className={styles.blogMainChild}>
            <h6>News & Insights</h6>
            <h2>Legal Updates and Thought Leadership</h2>
            <p>
              Our team of experts covers a wide range of topics, offering
              in-depth analysis and practical advice.
            </p>
          </div>

          <div className={styles.blogMainChild1}>
            <img src="/BlindJustice.svg" alt="" height={500} layout="responsive" />
          </div>
        </div>
      </div>

      <BlogParent>
        {mediumPage?.items && !isLoading ? (
          mediumPage.items.map((item, index) => (
            <NewPostCard
              key={index}
              item={item}
              onItemClick={() => handleItemClick(item)}
            />
          ))
        ) : (
          <p>Loading...</p>
        )}
      </BlogParent>
      {isModalOpen && selectedItem && (
        <SinglePostModal
          item={selectedItem}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Blog1;

const BlogParent = styled.div`
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  margin: 0 auto;
  max-width: 100%;
  margin-top: 10rem;

  @media only screen and (max-width: 420px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
