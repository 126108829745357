import React from "react";
import classes from "./About7.module.css";
import { useNavigate} from "react-router-dom";

const About7 = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className={classes.About7Primary}>
      <div className={`container ${classes.About7Main}`}>
        <div className={classes.About7MainParent}>
          <h4>Connect With Us</h4>
          <div className={classes.About7Parent}></div>
          <h5>Our team is ready to assist you on your inquiries</h5>
          <button onClick={handleGoBack}>Connect With Us</button>
        </div>
      </div>
    </div>
  );
};

export default About7;
