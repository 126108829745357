import React from "react";
import classes from "./Contact2.module.css";
// import OurOffice from "../../assets/images/OurOffice.svg";

const Contact2 = () => {
  return (
    <div className={`container ${classes.Contact2Main}`}>
      <h4>Our Office</h4>

      <div className={classes.Contact2Parent}>
        <div className={classes.Contact2Child}>
          <h5>Lagos, Nigeria</h5>
          <p>Block 6 Flat 2 Oshodi drive, Dolphin Estate </p>
          <a
            href="https://www.google.com/maps/place/AAA+CHAMBERS/@6.4570416,3.4137322,18z/data=!4m6!3m5!1s0x103b8b6940158279:0x4ddd1f9b728fbb77!8m2!3d6.4570415!4d3.4161141!16s%2Fg%2F11ghdkd3kp?authuser=0&entry=tts"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <h6>View map</h6>
          </a>
        </div>

        <div className={classes.Contact2Child2}>
          <img src="/OurOffice.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Contact2;
