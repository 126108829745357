import React from "react";
import Contact1 from "./Contact1";
import Contact2 from "./Contact2";
import Contact3 from "./Contact3";

const ContactIndex = () => {
  return (
    <div>
      <Contact1 />
      <Contact2 />
      <Contact3 />
    </div>
  );
};

export default ContactIndex;
