import React from "react";
// import Practice1Img from "../../assets/images/Practice1Img.svg";
import classes from "./Practice1.module.css";

const Practice1 = () => {
  return (
    <div className={`${classes.Practice1Main}`}>
      <div className={`container ${classes.Practice1Parent}`}>
        <div className={classes.Practice1Child}>
          <h6>Practice Areas</h6>
          <h2>Our Expertise in Legal Practice</h2>
        </div>

        <div className={classes.Practice1Image}>
          <img
            src="/Practice1Img.svg"
            alt=""
            height={400}
            width={300}
            layout="responsive"
          />
        </div>
      </div>
    </div>
  );
};

export default Practice1;
