import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FooterScreen from "./Screens/footer";
import NavbarScreen from "./Screens/navbar";
import IndexHome from "./Screens/Home/Index";
import PracticeIndex from "./Screens/PracticeArea/Index"
import AboutIndex from "./Screens/AboutUs/Index";
import BlogIndex from "./Screens/Blog/Index";
import ContactIndex from "./Screens/ContactUs/Index";

function App() {
  return (
    <Router>
      <NavbarScreen />
      <Routes>
        <Route path='/' element={<IndexHome/>} />
        <Route path='/practice' element={ <PracticeIndex/>} />
        <Route path='/about' element={ <AboutIndex/>} />
        <Route path='/blog' element={ <BlogIndex/>} />
        <Route path='/contact-us' element={ <ContactIndex/>} />
      </Routes>
      <FooterScreen />  
    </Router>
  );
}

export default App;
