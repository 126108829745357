import React from "react";
import classes from "./Practice3.module.css";
import { useNavigate } from "react-router-dom";
// import Practice3Img from "../../assets/images/Practice3Img.svg";

const Practice3 = () => {
  const navigate = useNavigate();

  const goToContact = () => {
    navigate("/contact-us");
  };

  return (
    <div className={`container ${classes.Practice3main}`}>
      <div className={classes.Practice3Parent}>
        <div className={classes.Practice3Parent1}>
          <img src="/Practice3Img.svg" alt="" layout="responsive" />
        </div>

        <div className={classes.Practice3Parent2}>
          <h4>Corporate & Commercial Practice</h4>

          <p>
            At A.A.C, we pride ourselves on providing state-of-the-art solutions
            to business and commercial issues. Our extensive experience in the
            sector enables us to provide precise, holistic solutions for
            corporate and commercial matters.
          </p>

          <button onClick={goToContact}>Contact Us</button>
        </div>
      </div>

      <div className={classes.Practice3Category}>
        <h4>General Litigation</h4>
        <h4>Construction law & Real Estate</h4>
        <h4>Chieftaincy matters</h4>
        <h4>Medical & Health law </h4>
        <h4>Labour law</h4>
        <h4>Criminal matters</h4>
        <h4>Company secretary and regulatory compliances</h4>
        <h4>Corporate law</h4>
        <h4>Dispute resolution</h4>
        <h4>Tech law</h4>
      </div>
    </div>
  );
};

export default Practice3;
