export const data = [
  {
    id: 1,
    title: "Dispute Resolution",
  },

  {
    id: 2,
    title: "Family Law",
  },

  {
    id: 3,
    title: "Corporate & Commercial Practice",
  },

  {
    id: 4,
    title: "Employment & Labour Law",
  },

  {
    id: 5,
    title: "Banking & Finance",
  },

  {
    id: 6,
    title: "Medical & Healthcare Law",
  },
  {
    id: 7,
    title: "Construction & Real Estate Development",
  },
  {
    id: 8,
    title: "Sports Law",
  },
];
