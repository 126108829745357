import React from "react";
import classes from "./Page4.module.css";
import { data } from "./HomeData";

const Page4 = () => {
  return (
    <div className={`container ${classes.Page4Main}`}>
      <div className={classes.Page4Header}>
        <h6>Our Practice</h6>
      </div>

      <div className={classes.Page4Parent}>
        <div className={classes.Page4Child1}>
          <h2>We provide Legal Services for Diverse Industries and Clients</h2>
        </div>
        <div className={classes.Page4SubChild}></div>

        <div className={classes.Page4Child2}>
          <h5>
            Explore our diverse range of legal services tailored to meet the
            needs of various industries and clients. From Corporate and
            Commercial Practice to Sports Law, we&apos;ve got you covered.

          </h5>
        </div>
      </div>

      <div className={`${classes.whatwedoMain}`}>
      {data.map((data) => (
        <div key={data.id} className={classes.whatwedoparent}>
          <div className={classes.whatweflex} >
            <h6> {data.title} </h6>
            <div className={classes.whatwedodiv}></div>
          </div>
        </div>
      ))}
    </div>


    </div>
  );
};

export default Page4;
