import React from "react";
import classes from "./Contact3.module.css";

const Contact3 = () => {
  return (
    <div>
      <div className={`container ${classes.fromParent} `}>
        <div className={classes.formMain}>
          <h6>Get in touch with us</h6>
        </div>

        <form
          name="contact"
          netlify
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit="submit"
          // action="/success/"
          className={`row g-3 ${classes.formMainParent} `}
        >
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <input name="bot-field" />
          </div>

          <div className="col-md-6">
            <input
              type="text"
              className={`form-control ${classes.formControl}`}
              placeholder="First name"
              aria-label="First name"
              name="first-name"
            />
          </div>
          <div className="col-md-6">
            <input
              type="text"
              className={`form-control ${classes.formControl}`}
              placeholder="Last name"
              aria-label="Last name"
              name="last-name"
            />
          </div>

          <div className="col-12">
            <input
              type="email"
              className={`form-control ${classes.formControl}`}
              placeholder="Email"
              aria-label="email"
              name="email"
            />
          </div>

          <div className="col-12">
            <input
              type="text"
              className={`form-control ${classes.formControl}`}
              aria-label="phone number"
              placeholder="Phone Number"
              name="number"
            />
          </div>

          <div className="col-12">
            <textarea
              className={`form-control ${classes.formControl}`}
              placeholder="Leave a comment here"
              aria-label="comment"
              id="Leave a comment here"
              name="comment"
            ></textarea>
          </div>

          <div className={`col-12 ${classes.formbutton} `}>
            <button type="submit">Sumbit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact3;
