import React from "react";
// import ContactMail from "../../assets/images/ContactMail.svg";
import classes from "./Contact1.module.css";

const Contact1 = () => {
  return (
    <div className={`container ${classes.Contact1Main}`}>
      <div className={classes.Contact1Parent}>
        <h1>Contact us</h1>
        <h5>
          Do you have a legal inquiry or need advice? Our team is ready to
          assist. Contact us via phone, email, or fill out the form below.
        </h5>
      </div>

      <div className={classes.Contact1Child}>
        <div className={classes.Contact1Child1}>
          <img src="/ContactMail.svg" alt="" />
          <h4>Email</h4>
          <h5>
            abegbeagboolachambers@gmail.com <br />
            Info@abegebagboolachambers.com
          </h5>
        </div>

        <div className={classes.Contact1Child1}>
          <img src="/ContactMail.svg" alt="" />
          <h4>Phone</h4>
          <h5>
            08137671921 <br />
            +2347067054810
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Contact1;
