import React from "react";
import classes from "./About3.module.css";

const About3 = () => {
  return (
    <div className={`container ${classes.About3Main}`}>
      <div className={classes.About3Container}>
        <div className={classes.About3Parent}>
          <h2>
            25+ <span>years</span>
          </h2>
          <p>
            Over 25 years of providing exceptional legal services and innovative
            solutions.
          </p>
        </div>
        <div className={classes.About3Parent}>
          <h2>
            500+ <span>Successful Cases</span>
          </h2>
          <p>
            Successfully resolved over 500 complex cases, ranging from
            high-stakes disputes to intricate regulatory matters.
          </p>
        </div>
        <div className={classes.About3Parent}>
          <h2>
            97% <span>Client Satisfaction Rate</span>
          </h2>
          <p>
            Achieved a remarkable 97% client satisfaction rate, demonstrating
            our commitment to delivering exceptional service.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About3;
