import React from "react";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page3 from "./Page3";
// import Page7 from "./Page7";
// import Page6 from './Page6'

const IndexHome = () => {
  return (
    <div>
      <Page1 />
      <Page3 />
      <Page2 />
      <Page4 />
      <Page5 />
      {/* <Page6/> */}
      {/* <Page7/> */}
    </div>
  );
};

export default IndexHome;
