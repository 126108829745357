import React from 'react'
import classes from "./Practice2.module.css"

const Practice2 = () => {
  return (
    <div className= {`container ${classes.Practice2Main}`}>
      <h2>Explore Our Areas of Practice</h2>
      <div className= {classes.Practice2Child}></div>
      <p>We offer a comprehensive range of legal services tailored to meet the diverse needs of our clients. Select an area to learn more</p>
    </div>
  )
}

export default Practice2
   