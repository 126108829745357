import React from "react";
import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

export default function SinglePostModal({ item, closeModal }) {
  const dialog = useRef();

  useEffect(() => {
    const modal = dialog.current;
    modal.showModal();
    return () => {
      modal.close();
    };
  }, []);

  return createPortal(
    <>
      <Backdrop onClick={closeModal}>
        <StyledModal ref={dialog}>
          <StyledPost>
            <div dangerouslySetInnerHTML={{ __html: `${item?.description}` }} />
          </StyledPost>
        </StyledModal>
      </Backdrop>
    </>,
    document.getElementById("modal")
  );
}

const StyledModal = styled.dialog`
  margin: 0;
  padding: 1rem 2rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  border: 0px solid none !important;
  background: "#000";
  border: none;
  border-radius: 8px;
  z-index: 100;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: slide-down-fade-in 300ms ease-out forwards;
  height: 100vh;

  @media only screen and (max-width: 420px) {
    width: 100%;
  }
  @media only screen and (min-width: 421px) and (max-width: 576px) {
    width: 80%;
  }

  @media only screen and (min-width: 577px) and (max-width: 768px) {
    width: 80%;
  }
  @media only screen and (min-width: 769px) and (max-width: 922px) {
    width: 80%;
  }
  @media only screen and (min-width: 923px) and (max-width: 1200px) {
    width: 60%;
  }
`;

const Backdrop = styled.div`
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer !important;
  z-index: 99;
`;

// const StyledModal2 = styled.dialog`
//   & > .ant-modal-wrap {
//     z-index: 3000;
//   }
// `;
const StyledPost = styled.div`
  figure figcaption {
    padding-top: 6px;
    a {
      font-size: 16px;
      font-weight: 700;
      &:hover {
        text-decoration: underline;
        color: var(--background-primary);
      }
    }
  }
  p {
    margin-bottom: 10px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #0c0c0c;
  }
  img {
    width: 100% !important;
    border-radius: 8px;
  }
`;
