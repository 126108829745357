import React, { useState} from "react";
import classes from "./navbar.module.css";
// import aacLogo from "../assets/images/aacLogo1.svg";
// import NavbarIcon from "../assets/images/NavbarIcon.svg";
import { useNavigate } from "react-router-dom";

const NavbarScreen = () => {
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);

  const toggleNavbar = () => {
    setIsToggled(!isToggled);
  };

  const goToContact = () => {
    navigate(-1);
  };

  return (
    <nav className={`navbar navbar-expand-lg fixed-top ${classes.ParentNav}`}>
      <div class="container-md">
        <div className={classes.navbarLogoMobil}>
          <a href="/" className={`navbar-brand`}>
            <img src="/aacLogo1.svg" width={128} height={50} alt="Ethnocentrique " />
          </a>
        </div>

        <button
          class={`navbar-toggler ms-auto ${classes.NavToggleImg}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleNavbar}
        >
          <img
            src="/NavbarIcon.svg"
            // src={isToggled ? NavbarIcon : NavbarIcon}
            width={20}
            height={20}
            alt="Ethnocentrique "
          />  
        </button>

        <div
          class={`collapse navbar-collapse  ${classes.navbarGrandMain} `}
          id="collapsibleNavbar"
        >
          <ul class={`navbar-nav ${classes.navbarMain}`}>
            <li className={`nav-item ${classes.navbarLi}`}>
              <a href="/practice">PRACTICE AREAS</a>
            </li>

            <li className={`nav-item ${classes.navbarLi}`}>
              <a href="/about">ABOUT US</a>
            </li>
            {/* <li className={`nav-item ${classes.navbarLi}`}>
              <a href="/blog">NEWS & INSIGHTS</a>
            </li> */}
          </ul>
          <div className={classes.NavButton}>
            <a href="/contact-us" onClick={goToContact}>
              {" "}
              CONTACT US{" "}
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavbarScreen;
