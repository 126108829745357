import React, { useState } from "react";
// import Team1 from "../../assets/images/Team1.svg";
// import Team2 from "../../assets/images/Team2.svg";
// import Team3 from "../../assets/images/Team2.svg";
import classes from "./Team.module.css";

const Team = () => {
  const teamProfile = [
    {
      id: 1,
      TeamMem1Name: "Ishola Agboola",
      TeamMem1Position: "Partner",
      image: "/Team1.svg",
      company: "Abegbe Agboola Chambers",
      Team1Profile: `Ishola Agboola is a founding partner at Agboola & Agboola. A fully serviced lawfirm that focuses on Commercial Transactions, Core Litigation Matters, Real Estate, Corporate Practice, and Advisory Services. 


      Before establishing Agboola & Agboola in 2020, Ishola has been serving as a managing partner in Abegbe Agboola Chambers since 2018.
      
      
      Ishola holds a Bachelor of Laws, LLB (Hons) and a Master of Law, LL.M from the University of Lagos, Akoka, Lagos.
      
      
      He is the Legal Adviser to various land owning communities within Eti-Osa Local Government Area of Lagos and its environs; and a few Operators in the Health Care Sector. He is also a part-time law lecturer at the Lagos State
      Polytechnic. 
      
      
      With over ten years in the legal space, Ishola has experience in dispute resolution, corporate and commercial practices, legal advisory services, secretarial services, office management, teaching, research, and drafting. 
      
      
      During his free time, he spends time learning new skill and swimming. He is also known to enjoy exploring new places and learning new languages.
      
      
        
          `,
    },
    {
      id: 2,
      TeamMem1Name: "Lookman Agboola",
      TeamMem1Position: "Principal Partner",
      image: "/Team2.svg",
      company: "Abegbe Agboola Chambers",
      Team1Profile: `
      Lookman Agboola is a principal partner, he has an undisputed passion for resolving complicated disputes . He is result driven with mantra let your work speak for you .
      
      He has engaged in Human rights , Chieftaincy and local  government matters , Real property litigation , Maritime law , Construction law , Real Estate Developement , Urban and regional planning law , Wills and Probate, Family law, Arbitration , Mediation and Negotiation , trade union disputes.
      
      He is instrumental to the development of ingenious and effective strategies for ensuring successful legal transactions . He's a historian per excellence with deep root for culture . 
      
      He has attended international summits such as the IBA , he was part of the committee for the amendment of the. 2012 Lagos State  civil procedure Rules.  He is a member of the advisory committee for the Oloto Stool . 
      
      Lookman is a member of NBA and IBA 
      
      Education 
      HND Lab Science Yabatech 
      LLB Unilag 
      Nigeria LAw School
      LLM Univeirsuty of Lagos                    
          `,
    },
    {
      id: 3,
      TeamMem1Name: "Ololade Agboola",
      TeamMem1Position: "Practice Manager",
      image: "/Team2.svg",
      company: "Abegbe Aboola Chambers",
      Team1Profile: `
      Ololade Agboola is the practice manager of the firm , she obtained her LLB from University of Lagos ,Her legal experience stems deligent services as legal researcher with Justice (name of the judge you worked with),
      
      She is a trial lawyer in Taxaion , Business advisory, Construction law , Registration Trademark , mediation and negotiation advocate ,Cheiftancy matters , Property Development., Legal Tech ,litigation ,Blockchain, Ecommerce on trade and. investment , wills and probate , family law , adoption 
      
      Loloade is a member of LAgos Food Bank Iniative , Nigeiran Bar Association , 
      
      Eduation 
      LLB Univeristy of LAgos 
      BL Nigeiran LAw School
      LLM in view University of LAgos
      Hubspot Content Marketi
                            
          `,
    },
    // {
    //   id: 4,
    //   TeamMem1Name: "Jeremiah Ubunama",
    //   TeamMem1Position: "Program Lead",
    //   image: Team4,
    //   company: "Ethnocentrique Limited",
    //   Team1Profile: `
    //       Jeremiah Ubunama is a management analyst with a background rooted in consulting - MSME cluster and capacity development, natural gas advisory and financial modelling, management advisory and partnership development.
    //       A graduate of political science, Jeremiah has served in numerous capacities across multiple industries as; a Senior Associate on partnerships for The Growth Platform - a pan-African infrastructure executed by the Bank of Industry (BOI) - Africa's foremost industrial bank, employing technology, big data and an extensive field agent network to deliver end-to-end design and execution of programmes targeted at Micro, Small and Medium Enterprises, for governments, international organizations, and private sector entities.
    //       An Investment Analyst for a boutique gas asset management and utilization company, where he led the development of financial models, and investment analysis to management on best investment decisions, profitable price points for the sale of natural gas to customers, and development of gas plants across Nigeria.
    //       Passionate about development, Jeremiah has contributed as an external consultant in the implementation of a DFID development funded program across skill and cluster development in Northern Nigeria, as well as the development of a framework for the strengthening of local and market institution in Nigeria funded by the GIZ. He has also participated as a Macroeconomist assistant on the Nigerian Green-bond Project, which was carried out in partnership with the World Bank, the Ministry of Environment, and the Federal Government of Nigeria. The Projects embarked upon were all aimed at economic, social, and human development in Nigeria in Nigeria.`,
    // },
  ];

  const [setCurrentPage] = useState(0);
  const [selectedTeamMemberId, setSelectedTeamMemberId] = useState(null);

  const handlePageChange = (selectedPage, teamMemberId) => {
    if (teamMemberId) {
      setSelectedTeamMemberId(teamMemberId);
    } else {
      setCurrentPage(selectedPage);
    }
  };

  return (
    <div className={`container ${classes.teamMain}`}>
      {/* <div className={classes.TeamHeaderFlex}>
        <h6>Our Team</h6>
      </div> */}

      <div
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        className={classes.teamFlex}
      >
        <div
          class={`modal fade ${classes.modalFade} `}
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div
            className={`modal-dialog modal-dialog-scrollable custom-dialog-width ${classes.teamscrolmodal} `}
          >
            <div className={`modal-content ${classes.teammodalcontent}`}>
              <div className={`modal-header ${classes.modalHeadermain}`}>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className={`modal-body ${classes.modalBodyMain}`}>
                {selectedTeamMemberId && (
                  <div>
                    <div className={classes.teamChild1}>
                      <img
                        src={
                          teamProfile.find(
                            (tm) => tm.id === selectedTeamMemberId
                          ).image
                        }
                        alt={
                          teamProfile.find(
                            (tm) => tm.id === selectedTeamMemberId
                          ).TeamMem1Name
                        }
                      />

                      <div className={classes.teamChildName1}>
                        <h6>
                          {
                            teamProfile.find(
                              (tm) => tm.id === selectedTeamMemberId
                            ).TeamMem1Name
                          }
                        </h6>
                        <p>
                          {
                            teamProfile.find(
                              (tm) => tm.id === selectedTeamMemberId
                            ).TeamMem1Position
                          }
                        </p>
                        <p>
                          {
                            teamProfile.find(
                              (tm) => tm.id === selectedTeamMemberId
                            ).company
                          }
                        </p>
                      </div>
                    </div>
                    <div className={classes.teamChildProfile}>
                      <p>
                        {teamProfile
                          .find((tm) => tm.id === selectedTeamMemberId)
                          .Team1Profile.replace(/\n/g, "\n")}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {teamProfile.slice(0).map((teamMember) => (
          <div key={teamMember.id} className={classes.teamChild}>
            <div
              onClick={() => handlePageChange(null, teamMember.id)}
              className={classes.teamChildImagetry}
            >
              <img
                src={teamMember.image}
                alt={teamMember.TeamMem1Name}
                layout="responsive"
                height={500}
                width={500}
              />
            </div>

            <div className={classes.teamChildName}>
              <h6>{teamMember.TeamMem1Name}</h6>
              <p>{teamMember.TeamMem1Position}</p>
              <p>{teamMember.company}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
